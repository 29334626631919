import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/new_filters.png';

const Content = () => {
  return (
    <div>
      <p>
        New filters have been released. From now on, you can filter by device group(mobile, tablet
        or desktop) or easily find sessions based on the device&apos;s screen size.
      </p>
      <ImageModal src={img} alt="Filters" title="New filters - device group" />
      <p className="mt-3" />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'New filters',
  url: '/blog/new-system-filters/',
  description: 'New system filters - Device and Screen size',
  author,
  img,
  date: '2019-04-28',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
